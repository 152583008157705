enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  SAVE_FESTIVAL = "saveFestival",
  GET_MY_FESTIVALS = "getMyFestivals",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  GET_CATEGORY = "getCategory",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_GATEGORY = "setCategory",
  SET_FESTIVAL_INFO = "setFestivalInfo",
  ADD_FESTIVAL_INFO = "addFestivalInfo",
  RESET_FESTIVAL_INFO = "resetFestivalInfo",
  OVERRIDE_FESTIVAL_INFO = "overrideFestivalInfo",

  SET_MY_ECOFESTIVALS = "setMyEcofestivals",
}

enum FestivalInformationKey {
  TEMP_FESTIVAL_DATA = "TEMP_FESTIVAL_DATA",
  MEMGER_SCALE = "FESTIVAL_MEMGER_SCALE",
  FESTIVAL_THEME = "FESTIVAL_THEME",
  FESTIVAL_HASHTAG = "FESTIVAL_HASHTAG",
}

export { Actions, Mutations, FestivalInformationKey };
