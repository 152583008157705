import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/main",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/main",
        name: "main",
        component: () => import("@/views/Main.vue"),
      },
      {
        path: "/myplan",
        name: "myplan",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/basicInfo",
        name: "기본정보",
        component: () => import("@/views/BasicInfo.vue"),
      },
      {
        path: "/ecoPartner",
        name: "에코파트너",
        component: () => import("@/views/EcoPartners.vue"),
      },
      {
        path: "/outline",
        name: "개요",
        component: () => import("@/views/Outline.vue"),
      },
      {
        path: "/purpose",
        name: "목적",
        component: () => import("@/views/Purpose.vue"),
      },
      {
        path: "/plan",
        name: "내용",
        component: () => import("@/views/plan/Wizard.vue"),
      },
      {
        path: "/detail",
        name: "세부계획",
        component: () => import("@/views/Detail.vue"),
      },
      {
        path: "/operation",
        name: "운영방안",
        component: () => import("@/views/Operation.vue"),
      },
      {
        path: "/pr",
        name: "홍보계획",
        component: () => import("@/views/Pr.vue"),
      },
      {
        path: "/budget",
        name: "예산계획",
        component: () => import("@/views/Budget.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/user/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/user/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/user/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
