import objectPath from "object-path";
import merge from "deepmerge";
import festival from "@/core/data/EcoFestival";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import { FestivalInfo, dbFestivalInfo } from "@/core/data/EcoFestivalType";
import { collapseItemProps } from "element-plus";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {
  festival: FestivalInfo;
  initial: FestivalInfo;
  myFestivals: Array<dbFestivalInfo>;
}

@Module
export default class FestivalModule extends VuexModule implements StoreInfo {
  festival: FestivalInfo = festival;
  initial: FestivalInfo = festival;
  myFestivals: Array<dbFestivalInfo> = [];

  /**
   * Get config from festival Info
   * @returns {function(path, defaultValue): *}
   */
  get festivalInfo() {
    return (path, defaultValue) => {
      return objectPath.get(this.festival, path, defaultValue);
    };
  }

  get myEcoFestivals() {
    return (path, defaultValue) => {
      return objectPath.get(this.myFestivals, path, defaultValue);
    };
  }

  @Mutation
  [Mutations.SET_MY_ECOFESTIVALS](payload): void {
    this.myFestivals = payload;
  }

  @Mutation
  [Mutations.RESET_FESTIVAL_INFO]() {
    this.festival = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_FESTIVAL_INFO](path, payload): void {
    this.festival = merge(this.festival, payload);
  }

  @Mutation
  [Mutations.SET_FESTIVAL_INFO](payload): void {
    this.festival = payload;
  }

  @Action
  [Actions.SAVE_FESTIVAL](data) {
    // console.log(data);
    return ApiService.post("/v1/ecofestival", data)
      .then((response) => {
        if (200 == response.status) {
          console.log(response);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            errorMessage: response.data.errorMessage,
          });
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          errorMessage: response.data.errorMessage,
        });
      });
  }

  @Action
  [Actions.GET_MY_FESTIVALS]() {
    return ApiService.get("/v1/my-ecofestivals")
      .then((response) => {
        if (200 == response.status) {
          this.context.commit(Mutations.SET_MY_ECOFESTIVALS, response.data);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            errorMessage: response.data.errorMessage,
          });
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          errorMessage: response.data.errorMessage,
        });
      });
  }
}
