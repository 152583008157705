import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.accessToken);
    JwtService.saveUserInfo(user);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }
  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyUserInfo();
  }

  @Action
  [Actions.LOGIN](credentials) {
    this.context.commit(Mutations.PURGE_AUTH);

    return ApiService.post("/v1/user/login", credentials)
      .then((response) => {
        if (200 == response.status) {
          // console.debug("로그인 성공!!");
          this.context.commit(Mutations.SET_AUTH, response.data);
        } else {
          // console.debug("로그인 실패!!");
          this.context.commit(Mutations.SET_ERROR, {
            errorMessage: response.data.errorMessage,
          });
        }
      })
      .catch(({ response }) => {
        // console.debug("로그인 에러!!");
        this.context.commit(Mutations.SET_ERROR, {
          errorMessage: response.data.errorMessage,
        });
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    this.context.commit(Mutations.PURGE_AUTH);

    return ApiService.post("/v1/user/register", credentials)
      .then((response) => {
        if (200 == response.status) {
          console.debug("가입 성공!!");
        } else {
          console.debug("가입 실패!!");
          this.context.commit(Mutations.SET_ERROR, {
            errorMessage: response.data.errorMessage,
          });
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          errorMessage: response.data.errorMessage,
        });
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          errorMessage: response.data.errorMessage,
        });
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.post("/authenticate", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);

          this.context.commit(Mutations.SET_ERROR, {
            errorMessage: "세션이 만료 되었습니다. 재로그인 해주세요.",
          });
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
