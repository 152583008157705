import FestivalInfo from "./EcoFestivalType";

const festival: FestivalInfo = {
  festivalTitle: "",
  theme: "",
  hashTags: [],
  outline: "",
  purpose: "",
  detail: "",
  operation: "",
  pr: "",
  budget: "",
};

export default festival;
