const ID_TOKEN_KEY = "id_token" as string;
const USER_INFO_KEY = "user_info" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getUserInfo = (): any | null => {
  const userInfo = window.localStorage.getItem(USER_INFO_KEY);
  if (null == userInfo) return null;
  else return JSON.parse(userInfo);
};

export const saveUserInfo = (userInfo: any): void => {
  window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
};

export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(USER_INFO_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getUserInfo,
  saveUserInfo,
  destroyUserInfo,
};
